<style lang="scss">
@import 'styles.scss';
</style>

<template>
    <v-fab-transition>
        <v-btn v-show="fab"
               dark
               fixed
               color="white"
               @click="toTop"
               class="custom-button-andrey pa-0 v-btn-scroll-top"
               elevation="1"
               v-scroll:#main-wrap-el="onScroll"
        >
            <v-icon large
                    color="primary"
            >
                keyboard_arrow_up
            </v-icon>
        </v-btn>
    </v-fab-transition>
</template>

<script>
export default {
    data: () => ({
        fab: false
    }),

    computed: {},
    methods: {
        onScroll(e) {
            this.fab = e.target.scrollTop > 100
        },
        toTop() {
            this.$vuetify.goTo(0, { container: '#main-wrap-el' })
        }
    }
}
</script>

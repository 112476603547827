export default axios => ({
  getInvoices(params) {
    return axios.get('/admin/invoices', {params})
  },
  postInvoice(params) {
    return axios.post('/admin/invoices', params)
  },
  putInvoice(params) {
    return axios.put(`/admin/invoices/${params.id}`, params)
  },
  getInvoice(params) {
    return axios.get(`/admin/invoices/${params.id}`, {params})
  },
  deleteInvoice(params) {
    return axios.delete(`/admin/invoices/${params.id}`)
  },
  chargeInvoice(params) {
    return axios.post(`/admin/invoices/${params.invoice_id}/charge`, params)
  },
  completePaymentInvoice(params) {
    return axios.post('/admin/invoices/complete-payment', params)
  },
});

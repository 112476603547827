export default axios => ({
  getPaymentMethods(params) {
    return axios.get('/admin/payment-methods', {params})
  },
  getPaymentMethod(params) {
    return axios.get(`/admin/payment-methods/${params.id}`)
  },
  postPaymentMethod(params) {
    return axios.post('/admin/payment-methods', params)
  },
  putPaymentMethod(params) {
    return axios.put(`/admin/payment-methods/${params.id}`, params)
  },
  deletePaymentMethod(params) {
    return axios.delete(`/admin/payment-methods/${params.id}`)
  },
  postPaymentMethodSetup(params) {
    return axios.post('/admin/payment-methods/setup', params)
  },
  postPaymentMethodStore(params) {
    return axios.post('/admin/payment-methods/store', params)
  },
});

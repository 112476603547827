import Vue from "vue";
import {downloadFile} from "@/plugins/downloadFile";

export default {
  state: {
  },
  actions: {
    async exportSales (state, params) {
      try {
        const response = await this._vm.$api.exports.exportSales(params);
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'processed'});
        await downloadFile(response.data, params.data.filename, response.headers['content-type']);
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'completed'});
      } catch (error) {
        if (!error?.canceled) state.commit('SET_SNACK', {text: error.data || '', type: 'error'});
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'canceled'});
      } finally {
        if (state.rootState.app.minimizeDownloadedFiles.includes(params.data.id)) {
          state.commit('DEL_DOWNLOAD_FILE', params.data.id);
          state.commit('DEL_MINIMIZE_DOWNLOAD_FILE', params.data.id);
        }
      }
    },
    async exportDetailedSales (state, params) {
      try {
        const response = await this._vm.$api.exports.exportDetailedSales(params);
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'processed'});
        await downloadFile(response.data, params.data.filename, response.headers['content-type']);
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'completed'});
      } catch (error) {
        if (!error?.canceled) state.commit('SET_SNACK', {text: error.data || '', type: 'error'});
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'canceled'});
      } finally {
        if (state.rootState.app.minimizeDownloadedFiles.includes(params.data.id)) {
          state.commit('DEL_DOWNLOAD_FILE', params.data.id);
          state.commit('DEL_MINIMIZE_DOWNLOAD_FILE', params.data.id);
        }
      }
    },
    async exportStudentsSales (state, params) {
      try {
        const response = await this._vm.$api.exports.exportStudentsSales(params);
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'processed'});
        await downloadFile(response.data, params.data.filename, response.headers['content-type']);
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'completed'});
      } catch (error) {
        if (!error?.canceled) state.commit('SET_SNACK', {text: error.data || '', type: 'error'});
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'canceled'});
      } finally {
        if (state.rootState.app.minimizeDownloadedFiles.includes(params.data.id)) {
          state.commit('DEL_DOWNLOAD_FILE', params.data.id);
          state.commit('DEL_MINIMIZE_DOWNLOAD_FILE', params.data.id);
        }
      }
    },
    async exportStudents (state, params) {
      try {
        const response = await this._vm.$api.exports.exportStudents(params);
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'processed'});
        await downloadFile(response.data, params.data.filename, response.headers['content-type']);
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'completed'});
      } catch (error) {
        if (!error?.canceled) state.commit('SET_SNACK', {text: error.data || '', type: 'error'});
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'canceled'});
      } finally {
        if (state.rootState.app.minimizeDownloadedFiles.includes(params.data.id)) {
          state.commit('DEL_DOWNLOAD_FILE', params.data.id);
          state.commit('DEL_MINIMIZE_DOWNLOAD_FILE', params.data.id);
        }
      }
    },
    async exportOrders (state, params) {
      try {
        const response = await this._vm.$api.exports.exportOrders(params);
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'processed'});
        await downloadFile(response.data, params.data.filename, response.headers['content-type']);
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'completed'});
      } catch (error) {
        if (!error?.canceled) state.commit('SET_SNACK', {text: error.data || '', type: 'error'});
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'canceled'});
      } finally {
        if (state.rootState.app.minimizeDownloadedFiles.includes(params.data.id)) {
          state.commit('DEL_DOWNLOAD_FILE', params.data.id);
          state.commit('DEL_MINIMIZE_DOWNLOAD_FILE', params.data.id);
        }
      }
    },
    async exportTransactions (state, params) {
      try {
        const response = await this._vm.$api.exports.exportTransactions(params);
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'processed'});
        await downloadFile(response.data, params.data.filename, response.headers['content-type']);
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'completed'});
      } catch (error) {
        if (!error?.canceled) state.commit('SET_SNACK', {text: error.data || '', type: 'error'});
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'canceled'});
      } finally {
        if (state.rootState.app.minimizeDownloadedFiles.includes(params.data.id)) {
          state.commit('DEL_DOWNLOAD_FILE', params.data.id);
          state.commit('DEL_MINIMIZE_DOWNLOAD_FILE', params.data.id);
        }
      }
    },
    async exportInvoices (state, params) {
      try {
        const response = await this._vm.$api.exports.exportInvoices(params);
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'processed'});
        await downloadFile(response.data, params.data.filename, response.headers['content-type']);
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'completed'});
      } catch (error) {
        if (!error?.canceled) state.commit('SET_SNACK', {text: error.data || '', type: 'error'});
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'canceled'});
      } finally {
        if (state.rootState.app.minimizeDownloadedFiles.includes(params.data.id)) {
          state.commit('DEL_DOWNLOAD_FILE', params.data.id);
          state.commit('DEL_MINIMIZE_DOWNLOAD_FILE', params.data.id);
        }
      }
    },
  },
  mutations: {
  },
  getters: {
  },
};

<style lang="scss">
  @import 'styles/styles.scss';
  @import 'styles/styles.sass';
</style>

<template>
  <div id="app" class="app-main">
    <v-app :style="{background: $vuetify.theme.themes.light.success5}">
      <snackbar-update :hashChanged="hashChanged" @reloadApp="reloadApp"/>
      <snackbar/>
      <download-file v-for="(file, index) in downloadedFiles" :key="file.id" :file="file" :index="index"/>

      <router-view/>
      <v-overlay :value="preloaderApp" z-index="11" id="main-overlay" opacity="0">
        <v-layout align-center justify-center>
          <v-img
              src="/MainLogoColour.png"
              lazy-src="/MainLogoColour.png"
              max-height="90"
              max-width="175"
              contain
              class="mb-2"
          ></v-img>
        </v-layout>
        <v-layout align-center justify-center>
          <v-progress-linear indeterminate color="primary" height="5"></v-progress-linear>
        </v-layout>
      </v-overlay>
    </v-app>
  </div>
</template>

<script>
import {refreshPageMixin} from '@/plugins/refresh-page.mixins';
import Cookies from 'js-cookie';

export default {
  name: 'App',
  components: {},
  mixins: [refreshPageMixin],
  data() {
    return {}
  },
  created() {
    window.addEventListener('focus', this.focus);
    window.addEventListener('blur', this.blur);
    this.init();
  },
  mounted() {
    this.$nextTick(() => {
      this.onResize();
      window.addEventListener('resize', this.onResize);
    });
    this.initVersionCheck('/version.json');
    setTimeout(() => this.$store.commit('SET_PRELOADER_APP', false), 1000)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('focus', this.focus);
    window.removeEventListener('blur', this.blur);
  },
  computed: {
    preloaderApp() {
      return this.$store.state.app.preloaderApp;
    },
    token() {
      return this.$store.state.user.token;
    },
    downloadedFiles() {
      return this.$store.state.app.downloadedFiles.filter(file => !this.$store.state.app.minimizeDownloadedFiles.includes(file.id));
    },
  },
  watch: {
    token(val) {
      if (val) this.$store.dispatch('getMe')
    }
  },
  methods: {
    onResize() {
      this.$store.commit('SET_WINDOW_WIDTH', window.innerWidth)
    },
    focus() {
      this.$store.commit('SET_WINDOW_ACTIVE', true);
    },
    blur() {
      this.$store.commit('SET_WINDOW_ACTIVE', false);
    },
    init() {
      if (Cookies.get('RTT')) {
        this.$store.dispatch('getMe')
            .then(() => {
              setTimeout(() => this.$store.commit('SET_PRELOADER_APP', false), 1000);
            })
      } else {
        setTimeout(() => this.$store.commit('SET_PRELOADER_APP', false), 1000)
      }
    },
  }
}
</script>

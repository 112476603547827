export default {
  state: {
  },
  actions: {
    async getEvents (state, params) {
      return await this._vm.$api.events.getEvents(params)
    },
    async getEvent (state, params) {
      return await this._vm.$api.events.getEvent(params)
    },
    async postEvent (state, params) {
      return await this._vm.$api.events.postEvent(params)
    },
    async putEvent (state, params) {
      return await this._vm.$api.events.putEvent(params)
    },
    async deleteEvent (state, params) {
      return await this._vm.$api.events.deleteEvent(params)
    },
    async notifyEvent (state, params) {
      return await this._vm.$api.events.notifyEvent(params)
    },
  },
  mutations: {
  },
  getters: {
  },
};

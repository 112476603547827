<template>
    <ul :class="[prefixCls + '-list']">
        <li
            v-for="file in files"
            :key="file.name"
            :class="fileCls(file)"
            @click="handleClick(file)">
            <div>
                <v-icon small>{{format(file)}}</v-icon>
                <span @click="handlePreview(file)">{{ file.name }}</span>
            </div>
            <!--<Icon-->
                <!--type="ios-close-empty"-->
                <!--:class="[prefixCls + '-list-remove']"-->
                <!--v-show="file.status === 'finished'"-->
                <!--@click.native="handleRemove(file)"></Icon>-->
            <v-progress-linear :value="parsePercentage(file.percentage)"></v-progress-linear>
        </li>
    </ul>
</template>
<script>
    const prefixCls = 'v-custom-upload';

    export default {
        name: 'UploadList',
        props: {
            files: {
                type: Array,
                default () {
                    return [];
                }
            }
        },
        data () {
            return {
                prefixCls: prefixCls
            };
        },
        computed: {},
        methods: {
            fileCls (file) {
                return [
                    `${prefixCls}-list-file`,
                    {
                        [`${prefixCls}-list-file-finish`]: file.status === 'finished'
                    }
                ];
            },
            handleClick (file) {
                this.$emit('on-file-click', file);
            },
            handlePreview (file) {
                this.$emit('on-file-preview', file);
            },
            handleRemove (file) {
                this.$emit('on-file-remove', file);
            },
            format (file) {
                const format = file.name.split('.').pop().toLocaleLowerCase() || '';
                let type = 'attach_file';

                if (['gif', 'jpg', 'jpeg', 'png', 'bmp', 'webp'].indexOf(format) > -1) {
                    type = 'image';
                }
                if (['mp4', 'm3u8', 'rmvb', 'avi', 'swf', '3gp', 'mkv', 'flv'].indexOf(format) > -1) {
                    type = 'local_movies';
                }
                if (['mp3', 'wav', 'wma', 'ogg', 'aac', 'flac'].indexOf(format) > -1) {
                    type = 'music_note';
                }
                if (['doc', 'txt', 'docx', 'pages', 'epub', 'pdf', 'numbers', 'csv', 'xls', 'xlsx'].indexOf(format) > -1) {
                    type = 'description';
                }
                if (['keynote', 'ppt', 'pptx'].indexOf(format) > -1) {
                    type = 'slideshow';
                }

                return type;
            },
            parsePercentage (val) {
                return parseInt(val, 10);
            }
        }
    };
</script>

export default {
  state: {
  },
  actions: {
    async getPaymentMethods (state, params) {
      return await this._vm.$api.paymentMethods.getPaymentMethods(params)
    },
    async getPaymentMethod (state, params) {
      return await this._vm.$api.paymentMethods.getPaymentMethod(params)
    },
    async postPaymentMethod (state, params) {
      return await this._vm.$api.paymentMethods.postPaymentMethod(params)
    },
    async putPaymentMethod (state, params) {
      return await this._vm.$api.paymentMethods.putPaymentMethod(params)
    },
    async deletePaymentMethod (state, params) {
      return await this._vm.$api.paymentMethods.deletePaymentMethod(params)
    },
    async postPaymentMethodSetup (state, params) {
      return await this._vm.$api.paymentMethods.postPaymentMethodSetup(params)
    },
    async postPaymentMethodStore (state, params) {
      return await this._vm.$api.paymentMethods.postPaymentMethodStore(params)
    },
  },
  mutations: {
  },
  getters: {
  },
};

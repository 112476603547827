export default {
  state: {
  },
  actions: {
    async getTrainings (state, params) {
      return await this._vm.$api.trainings.getTrainings(params)
    },
    async getTraining (state, params) {
      return await this._vm.$api.trainings.getTraining(params)
    },
    async postTraining (state, params) {
      return await this._vm.$api.trainings.postTraining(params)
    },
    async putTraining (state, params) {
      return await this._vm.$api.trainings.putTraining(params)
    },
    async deleteTraining (state, params) {
      return await this._vm.$api.trainings.deleteTraining(params)
    },
  },
  mutations: {
  },
  getters: {
  },
};

export default axios => ({
  getNotifications(params) {
    return axios.get('/admin/notifications', {params})
  },
  getNotification(params) {
    return axios.get(`/admin/notifications/${params.id}`, {params})
  },
  postNotification(params) {
    return axios.post('/admin/notifications', params)
  },
  putNotification(params) {
    return axios.put(`/admin/notifications/${params.id}`, params)
  },
  deleteNotification(params) {
    return axios.delete(`/admin/notifications/${params.id}`)
  },
  markReadNotification(params) {
    return axios.post(`/admin/notifications/${params.id}/mark-read`)
  },
  markUnreadNotification(params) {
    return axios.post(`/admin/notifications/${params.id}/mark-unread`)
  },
  markReadAllNotification() {
    return axios.post('/admin/notifications/mark-all-read')
  },
});

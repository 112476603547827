export default axios => ({
  getTransactions(params) {
    return axios.get('/admin/transactions', {params})
  },
  getTransaction(params) {
    return axios.get(`/admin/transactions/${params.id}`, {params})
  },
  postTransactionRefund(params) {
    return axios.post(`/admin/transactions/${params.id}/refund`, params)
  },
  postTransactionRequestRefund(params) {
    return axios.post(`/admin/transactions/${params.id}/request-refund`, params)
  },
});

export default axios => ({
  getProducts(params) {
    return axios.get('/admin/products', {params})
  },
  getProduct(params) {
    return axios.get(`/admin/products/${params.id}`)
  },
  postProduct(params) {
    return axios.post('/admin/products', params)
  },
  putProduct(params) {
    return axios.put(`/admin/products/${params.id}`, params)
  },
  deleteProduct(params) {
    return axios.delete(`/admin/products/${params.id}`)
  },
});

export default {
  state: {
  },
  actions: {
    async getPages (state, params) {
      return await this._vm.$api.pages.getPages(params)
    },
    async getPage (state, params) {
      return await this._vm.$api.pages.getPage(params)
    },
    async postPage (state, params) {
      return await this._vm.$api.pages.postPage(params)
    },
    async putPage (state, params) {
      return await this._vm.$api.pages.putPage(params)
    },
    async deletePage (state, params) {
      return await this._vm.$api.pages.deletePage(params)
    },
    async attachEntitiesPage (state, params) {
      return await this._vm.$api.pages.attachEntitiesPage(params)
    },
  },
  mutations: {
  },
  getters: {
  },
};

import Main from '@/pages/Main.vue'
import AuthMain from '@/pages/auth-pages/AuthMain.vue'

export const authPages = [
  {
    component: AuthMain,
    path: '/login_index',
    name: 'login_index',
    meta: {
      title: 'Login'
    },
    children: [
      { path: '/login', name: 'login', component: () => import('@/pages/auth-pages/login') }
    ]
  },
  {
    component: AuthMain,
    path: '/forgot_index',
    name: 'forgot_index',
    meta: {
      title: 'Forgot'
    },
    children: [
      { path: '/forgot', name: 'forgot', component: () => import('@/pages/auth-pages/forgot') }
    ]
  },
  {
    component: AuthMain,
    path: '/reset_index',
    name: 'reset_index',
    meta: {
      title: 'Reset'
    },
    children: [
      { path: '/reset', name: 'reset', component: () => import('@/pages/auth-pages/reset') }
    ]
  },
  {
    component: AuthMain,
    path: '/verify_index',
    name: 'verify_index',
    meta: {
      title: 'Verify'
    },
    children: [
      { path: '/verify', name: 'verify', component: () => import('@/pages/auth-pages/verify') }
    ]
  },
  {
    component: AuthMain,
    path: '/verify_error_index',
    name: 'verify_error_index',
    meta: {
      title: 'Verify error'
    },
    children: [
      { path: '/verify-error', name: 'verify_error', component: () => import('@/pages/auth-pages/verify-error') }
    ]
  }
];

export const errorPages = [
  {
    path: '/403',
    meta: {
      title: '403-Insufficient permissions'
    },
    name: 'error-403',
    component: () => import('@/pages/error-pages/403')
  },
  {
    path: '/500',
    meta: {
      title: '500-Server error'
    },
    name: 'error-500',
    component: () => import('@/pages/error-pages/500')
  },
  {
    path: '/404',
    name: 'error-404',
    alias: '*',
    meta: {
      title: '404-Page does not exist'
    },
    component: () => import('@/pages/error-pages/404')
  }
];

export const adminRouter = [
  {
    path: '/dashboard_index',
    name: 'dashboard_index',
    title: 'Dashboard',
    access: ['admin'],
    icon: 'dashboard',
    component: Main,
    menuList: 'menuList1',
    children: [
      { path: '/dashboard', title: 'Dashboard', access: ['admin'], name: 'dashboard', component: () => import('@/pages/main-pages/dashboard') }
    ]
  },
]

export const menu1Router = [
  {
    path: '/orders_index',
    name: 'orders_index',
    title: 'Orders',
    access: ['admin', 'administrative', 'sales_manager'],
    icon: 'shopping_cart',
    component: Main,
    menuList: 'menuList1',
    children: [
      { path: '/orders', title: 'Orders', access: ['admin', 'administrative', 'sales_manager'], name: 'orders', component: () => import('@/pages/main-pages/orders') },
      { path: '/orders/:id', title: 'Order', access: ['admin', 'administrative', 'sales_manager'], name: 'order', noMenu: true, component: () => import('@/pages/main-pages/order') },
    ]
  },
  {
    path: '/invoices_index',
    name: 'invoices_index',
    title: 'Invoices',
    access: ['admin', 'administrative', 'sales_manager'],
    icon: 'attach_money',
    component: Main,
    menuList: 'menuList1',
    children: [
      { path: '/invoices', title: 'Invoices', access: ['admin', 'administrative', 'sales_manager'], name: 'invoices', component: () => import('@/pages/main-pages/invoices') },
    ]
  },
  {
    path: '/bookings_index',
    name: 'bookings_index',
    title: 'Bookings',
    access: ['admin', 'administrative', 'sales_manager'],
    icon: 'more_time',
    component: Main,
    menuList: 'menuList1',
    children: [
      { path: '/bookings', title: 'Bookings', access: ['admin', 'administrative', 'sales_manager'], name: 'bookings', component: () => import('@/pages/main-pages/bookings') }
    ]
  },
  {
    path: '/transactions_index',
    name: 'transactions_index',
    title: 'Transactions',
    access: ['admin', 'administrative', 'sales_manager'],
    icon: 'payments',
    component: Main,
    menuList: 'menuList1',
    children: [
      { path: '/transactions', title: 'Transactions', access: ['admin', 'administrative', 'sales_manager'], name: 'transactions', component: () => import('@/pages/main-pages/transactions') }
    ]
  },
  {
    path: '/students_index',
    name: 'users_index',
    title: 'Students',
    access: ['admin', 'administrative', 'sales_manager'],
    icon: 'people_alt',
    component: Main,
    menuList: 'menuList1',
    children: [
      { path: '/students', title: 'Students', access: ['admin', 'administrative', 'sales_manager'], name: 'users', component: () => import('@/pages/main-pages/users') },
      { path: '/students/:id', title: 'Student', access: ['admin', 'administrative', 'sales_manager'], name: 'user', noMenu: true, component: () => import('@/pages/main-pages/user') },
    ]
  },
  {
    path: '/coupons_index',
    name: 'coupons_index',
    title: 'Coupons',
    access: ['admin', 'administrative', 'sales_manager'],
    icon: 'discount',
    component: Main,
    menuList: 'menuList1',
    children: [
      { path: '/coupons', title: 'Coupons', access: ['admin', 'administrative', 'sales_manager'], name: 'coupons', component: () => import('@/pages/main-pages/coupons') },
    ]
  },
];
export const menu2Router = [
  {
    path: '/posts_index',
    name: 'posts_index',
    title: 'Posts',
    access: ['admin', 'administrative', 'content_manager'],
    icon: 'library_books',
    component: Main,
    menuList: 'menuList2',
    children: [
      { path: '/posts', title: 'Posts', access: ['admin', 'administrative', 'content_manager'], name: 'posts', component: () => import('@/pages/main-pages/posts') }
    ]
  },
  {
    path: '/bio-pages_index',
    name: 'bio-pages_index',
    title: 'Bio Pages',
    access: ['admin', 'administrative', 'content_manager'],
    icon: '$doctor',
    component: Main,
    menuList: 'menuList2',
    children: [
      { path: '/bio-pages', title: 'Bio Pages', access: ['admin', 'administrative', 'content_manager'], name: 'bio-pages', component: () => import('@/pages/main-pages/bio-pages') }
    ]
  },
  {
    path: '/courses_index',
    name: 'courses_index',
    title: 'Courses',
    access: ['admin', 'administrative', 'content_manager'],
    icon: 'menu_book',
    component: Main,
    menuList: 'menuList2',
    children: [
      { path: '/courses', title: 'Courses', access: ['admin', 'administrative', 'content_manager'], name: 'courses', component: () => import('@/pages/main-pages/courses') },
      { path: '/courses/:id', title: 'Course', access: ['admin', 'administrative', 'content_manager'], name: 'course', noMenu: true, component: () => import('@/pages/main-pages/course') },
    ]
  },
  {
    path: '/course-packages_index',
    name: 'course-packages_index',
    title: 'Course Packages',
    access: ['admin', 'administrative', 'content_manager'],
    icon: 'inventory',
    component: Main,
    menuList: 'menuList2',
    children: [
      { path: '/course-packages', title: 'Course Packages', access: ['admin', 'administrative', 'content_manager'], name: 'course-packages', component: () => import('@/pages/main-pages/course-packages') },
      { path: '/course-packages/:id', title: 'Course Package', access: ['admin', 'administrative', 'content_manager'], name: 'course-package', noMenu: true, component: () => import('@/pages/main-pages/course-package') },
    ]
  },
  {
    path: '/events_index',
    name: 'events_index',
    title: 'Events',
    access: ['admin', 'administrative', 'content_manager'],
    icon: 'pending_actions',
    component: Main,
    menuList: 'menuList2',
    children: [
      { path: '/events', title: 'Events', access: ['admin', 'administrative', 'content_manager'], name: 'events', component: () => import('@/pages/main-pages/events') }
    ]
  },
  {
    path: '/event-pools_index',
    name: 'event-pools_index',
    title: 'Event Pools',
    access: ['admin', 'administrative', 'content_manager'],
    icon: 'group_work',
    component: Main,
    menuList: 'menuList2',
    children: [
      { path: '/event-pools', title: 'Event Pools', access: ['admin', 'administrative', 'content_manager'], name: 'event-pools', component: () => import('@/pages/main-pages/event-pools') }
    ]
  },
  {
    path: '/job-board_index',
    name: 'job-board_index',
    title: 'Job Board',
    access: ['admin', 'administrative', 'content_manager'],
    icon: 'work_outline',
    component: Main,
    menuList: 'menuList2',
    children: [
      { path: '/job-board', title: 'Job Board', access: ['admin', 'administrative', 'content_manager'], name: 'job-board', component: () => import('@/pages/main-pages/job-board') }
    ]
  },
  {
    path: '/files_index',
    name: 'files_index',
    title: 'Files',
    access: ['admin', 'administrative', 'content_manager'],
    icon: 'folder_open',
    component: Main,
    menuList: 'menuList2',
    children: [
      { path: '/files', title: 'Files', access: ['admin', 'administrative', 'content_manager'], name: 'files', component: () => import('@/pages/main-pages/files') }
    ]
  },
];
export const bottomRouter = [
  {
    path: '/settings_index',
    name: 'settings_index',
    title: 'Settings',
    access: ['admin', 'administrative'],
    icon: 'settings',
    component: Main,
    children: [
      { path: '/settings', title: 'Settings', access: ['admin', 'administrative'], name: 'settings', component: () => import('@/pages/main-pages/settings') }
    ]
  },
  {
    path: '/training_index',
    name: 'training_index',
    title: 'Training',
    access: ['admin', 'administrative'],
    icon: 'question_mark',
    component: Main,
    children: [
      { path: '/training', title: 'Training', access: ['admin', 'administrative', 'sales_manager', 'content_manager'], name: 'training', component: () => import('@/pages/main-pages/training') }
    ]
  },
];

export const otherRouter = [
  {
    path: '/',
    name: 'main',
    title: 'Main',
    icon: 'insert_chart',
    component: Main
  },
];

export const appRouter = [
  ...adminRouter,
  ...menu1Router,
  ...menu2Router,
];

export const routers = [
  ...authPages,
  ...appRouter,
  ...bottomRouter,
  ...otherRouter,
  ...errorPages
];

<style lang="scss">
@import 'styles.scss';
</style>

<template>
    <v-dialog v-model="dialog"
              :max-width="options.width"
              @keydown.esc.prevent.exact="cancel"
              @keydown.enter.prevent.exact="agree"
              :style="{ zIndex: options.zIndex }"
              :persistent="options.persistent"
    >
        <v-card class="confirm-modal" :color="options.color">
            <v-toolbar dark
                       :color="options.color"
                       flat
            >
                <v-toolbar-title class="text-h5 font-weight-regular px-4 py-1">
                    {{ title }}
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pt-6 pb-1 px-8 white text-subtitle-1 font-weight-light">
                <span v-show="!!message"
                      v-html="message"
                      class="text--primary"
                />
                <div v-if="options.blocked"
                     class="mt-5"
                >
                    <div v-html="termsBlocked"
                         class="text--primary"
                    ></div>
                    <v-checkbox color="primary"
                                v-model="checkbox"
                                label="I Agree"
                    ></v-checkbox>
                </div>
            </v-card-text>
            <v-card-actions class="px-5 pb-4 white">
                <v-col v-if="options.cancel">
                    <v-btn class="text--secondary"
                           text
                           block
                           @click.native="close"
                           elevation="0"
                    >
                      <v-icon class="mr-2">close</v-icon>
                        Cancel
                    </v-btn>
                </v-col>
                <v-col v-if="options.noCancel">
                    <v-btn class="text--secondary"
                           text
                           block
                           @click.native="cancel"
                           elevation="0"
                    >
                      <v-icon class="mr-2">cancel</v-icon>
                        {{`${options.buttonText ? `No, Don’t ${options.buttonText}` : 'No'}`}}
                    </v-btn>
                </v-col>
                <v-col>
                    <v-btn :color="options.color"
                           block
                           :disabled="options.blocked && !checkbox"
                           elevation="3"
                           @click.native="agree"
                    >
                      <v-icon class="mr-2">{{options.color === 'error' ? 'warning' : 'check_circle_outline'}}</v-icon>
                        {{ options.noCancel ? `${options.buttonText ? `Yes, ${options.buttonText}` : 'Yes'}` : 'Ok' }}
                    </v-btn>
                </v-col>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        checkbox: false,
        options: {
            color: 'primary',
            width: 400,
            zIndex: 250,
            persistent: false,
            cancel: false,
            blocked: false,
            noCancel: true,
            buttonText: '',
        },
        defaultOptions: {
            color: 'primary',
            width: 400,
            zIndex: 250,
            persistent: false,
            cancel: false,
            blocked: false,
            noCancel: true,
            buttonText: '',
        }
    }),
    computed: {
        termsBlocked() {
            return this.$store.state.app.termsBlocked;
        },
    },
    methods: {
        open(title, message, options) {
            this.dialog = true;
            this.title = title;
            this.message = message;
            this.options = Object.assign(this.options, options);
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject
            })
        },
        agree() {
            if (!this.options.blocked || this.options.blocked && this.checkbox) {
                this.resolve(true);
                this.close();
            }
        },
        cancel() {
            if (this.options.noCancel) {
                this.resolve(false);
                this.close();
            }
        },
        close() {
            this.dialog = false;
            this.reject();
            setTimeout(() => {
                this.options = Object.assign(this.options, this.defaultOptions);
                this.checkbox = false;
            }, 300)
        }
    }
}
</script>

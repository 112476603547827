export default axios => ({
  getCities(params) {
    return axios.get('/admin/cities', {params})
  },
  getStates(params) {
    return axios.get('/admin/cities/states', {params})
  },
  getCity(params) {
    return axios.get(`/admin/cities/${params.id}`, {params})
  },
  postCity(params) {
    return axios.post('/admin/cities', params)
  },
  putCity(params) {
    return axios.put(`/admin/cities/${params.id}`, params)
  },
  deleteCity(params) {
    return axios.delete(`/admin/cities/${params.id}`)
  },
});

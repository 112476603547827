export default {
  state: {
    specialitiesAll: [],
  },
  actions: {
    async getSpecialities (state, params) {
      return await this._vm.$api.specialities.getSpecialities(params)
    },
    async getSpeciality (state, params) {
      return await this._vm.$api.specialities.getSpeciality(params)
    },
    async postSpeciality (state, params) {
      return await this._vm.$api.specialities.postSpeciality(params)
    },
    async putSpeciality (state, params) {
      return await this._vm.$api.specialities.putSpeciality(params)
    },
    async deleteSpeciality (state, params) {
      return await this._vm.$api.specialities.deleteSpeciality(params)
    },

    async getSpecialitiesAll ({ commit }, params) {
      try {
        const param = Object.assign({}, params, {
          count: 'nolimit',
        });

        const res = await this._vm.$api.specialities.getSpecialities(param);
        commit('SET_SPECIALITIES_ALL', res.data.data ? res.data.data : res.data);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
  },
  mutations: {
    SET_SPECIALITIES_ALL (state, data) {
      state.specialitiesAll = data;
    },
  },
  getters: {
  },
};

import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app';
import user from './modules/user';
import files from './modules/files';
import orders from './modules/orders';
import invoices from './modules/invoices';
import courses from './modules/courses';
import coursePackages from './modules/coursePackages';
import pages from './modules/pages';
import products from './modules/products';
import settings from './modules/settings';
import users from './modules/users';
import bookings from './modules/bookings';
import events from './modules/events';
import transactions from './modules/transactions';
import jobs from './modules/jobs';
import dashboard from './modules/dashboard';
import paymentMethods from './modules/paymentMethods';
import cities from './modules/cities';
import notifications from './modules/notifications';
import specialities from './modules/specialities';
import coupons from './modules/coupons';
import exports from './modules/exports';
import eventPools from './modules/eventPools';
import bioPages from './modules/bioPages';
import trainings from './modules/trainings';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    user,
    files,
    orders,
    invoices,
    courses,
    coursePackages,
    pages,
    products,
    settings,
    users,
    bookings,
    events,
    transactions,
    jobs,
    dashboard,
    paymentMethods,
    cities,
    notifications,
    specialities,
    coupons,
    exports,
    eventPools,
    bioPages,
    trainings,
  },
  state: {},
  mutations: {},
  actions: {},
})

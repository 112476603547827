export default axios => ({
  getSettings(params) {
    return axios.get('/admin/settings', {params})
  },
  getSetting(params) {
    return axios.get(`/admin/settings/${params.id}`)
  },
  postSetting(params) {
    return axios.post('/admin/settings', params)
  },
  putSetting(params) {
    return axios.put(`/admin/settings/${params.id}`, params)
  },
  deleteSetting(params) {
    return axios.delete(`/admin/settings/${params.id}`)
  },
});

export default axios => ({
  getSpecialities(params) {
    return axios.get('/admin/specialities', {params})
  },
  getSpeciality(params) {
    return axios.get(`/admin/specialities/${params.id}`, {params})
  },
  postSpeciality(params) {
    return axios.post('/admin/specialities', params)
  },
  putSpeciality(params) {
    return axios.put(`/admin/specialities/${params.id}`, params)
  },
  deleteSpeciality(params) {
    return axios.delete(`/admin/specialities/${params.id}`)
  },
});

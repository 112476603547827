export default axios => ({
  getCoupons(params) {
    return axios.get('/admin/coupons', {params})
  },
  getCoupon(params) {
    return axios.get(`/admin/coupons/${params.id}`, {params})
  },
  postCoupon(params) {
    return axios.post('/admin/coupons', params)
  },
  putCoupon(params) {
    return axios.put(`/admin/coupons/${params.id}`, params)
  },
  deleteCoupon(params) {
    return axios.delete(`/admin/coupons/${params.id}`)
  },
});

import Vue from 'vue'
import Router from 'vue-router'
import { routers, adminRouter, menu1Router, menu2Router} from './routers';
import Cookies from 'js-cookie';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routers
});

const getRouterObjByName = function (routers, name) {
// role === 1 - Admin

  if (!name || !routers || !routers.length) {
    return null;
  }

  let routerObj = null;
  for (let item of routers) {
    if (item.name === name) {
      item.role = router?.app?.$store?.state?.user?.user?.role;
      return item;
    }
    routerObj = getRouterObjByName(item.children, name);
    if (routerObj) {
      return routerObj;
    }
  }
  return null;
};

const getRole = function () {
  return new Promise((resolve) => {
    let _timer1 = setInterval(() => {
      if (router.app?.$store?.state?.user?.user?.role) {
        clearInterval(_timer1);
        resolve(router.app.$store.state.user.user.role)
      }
    }, 300)
  })
};

router.beforeEach(async (to, from, next) => {

  if (Cookies.get('RTT') && !router.app?.$store?.state?.user?.user?.role) {
    await getRole();
  }

  const curRouterObj = getRouterObjByName(routers, to.name);

  if (!Cookies.get('RTT') && to.name !== 'login' && to.name !== 'forgot' && to.name !== 'verify' && to.name !== 'verify_error' && to.name !== 'reset') {
    setTimeout(() => {
      router.app.$store.dispatch('logout');
    });
    next({
      name: 'login'
    });
  } else if (Cookies.get('RTT') && (to.name === 'login' || to.name === 'forgot' || to.name === 'verify' || to.name === 'verify_error' || to.name === 'reset')) {
    next({
      name: 'main'
    });
  } else if (to.name === 'main') {
    if (curRouterObj.role === 'admin') {
      next({
        name: adminRouter[0].children[0].name
      });
    } else if (curRouterObj.role === 'administrative' || curRouterObj.role === 'sales_manager') {
      next({
        name: menu1Router[0].children[0].name
      });
    } else if (curRouterObj.role === 'content_manager') {
      next({
        name: menu2Router[0].children[0].name
      });
    }
  } else if (!curRouterObj) {
    next({
      replace: true,
      name: 'error-404'
    });
  } else if (curRouterObj.access && !curRouterObj.access.includes(curRouterObj.role)) {
    next({
      replace: true,
      name: 'error-403'
    });
  } else {
    next()
  }
});

router.afterEach(() => {});

export default axios => ({
  getEventPools(params) {
    return axios.get('/admin/pools', {params})
  },
  getEventPool(params) {
    return axios.get(`/admin/pools/${params.id}`, {params})
  },
  postEventPool(params) {
    return axios.post('/admin/pools', params)
  },
  putEventPool(params) {
    return axios.put(`/admin/pools/${params.id}`, params)
  },
  deleteEventPool(params) {
    return axios.delete(`/admin/pools/${params.id}`)
  },
});

import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import BankTransferSvg from '@/components/icons/bank-transfer-svg'
import CashDollarSvg from '@/components/icons/cash-dollar-svg'
import DoctorSvg from '@/components/icons/doctor-svg'

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 768,
    },
  },
  icons: {
    iconfont: 'md',
    values: {
      bankTransfer: {
        component: BankTransferSvg,
      },
      cashDollar: {
        component: CashDollarSvg,
      },
      doctor: {
        component: DoctorSvg,
      },
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // primary: '#6D98FF',
        // success: '#7FC908',
        // error: '#FF5C5C',
        // warning: '#E99700',
        // info: '#6FBAEC',
        primary: '#24346b',
        success: '#059897',
        error: '#dd0000',
        warning: '#e39300',
        info: '#008deb',
        background: '#ffffff',
        title: '#fafafa',
        text55: '#737373',
        chips: '#bbbbbb',
        primary85: '#455280',
        primary70: '#667196',
        primary10: '#d6dee4',
        primary5: '#e0e9eb',
        success85: '#2ba6a6',
        success70: '#4eb4b4',
        success12: '#e0f1f1',
        success10: '#e5f4f4',
        success5: '#EBF3F3',
        successCustom: '#C6EAEA',
        error85: '#e12626',
        error70: '#e34a4a',
        warning85: '#e6a226',
        warning70: '#eab24c',
        info70: '#4caef0',
        purple70: '#9575cd',
      },
    },
  },
});

export default {
  state: {
    states: [],
  },
  actions: {
    async getCities (state, params) {
      return await this._vm.$api.cities.getCities(params)
    },
    async getCity (state, params) {
      return await this._vm.$api.cities.getCity(params)
    },
    async postCity (state, params) {
      return await this._vm.$api.cities.postCity(params)
    },
    async putCity (state, params) {
      return await this._vm.$api.cities.putCity(params)
    },
    async deleteCity (state, params) {
      return await this._vm.$api.cities.deleteCity(params)
    },
    async getStates ({ commit }, params) {
      try {
        const res = await this._vm.$api.cities.getStates(params);
        let data = res.data.data ? res.data.data : res.data;
        commit('SET_STATES_ALL', data);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
  },
  mutations: {
    SET_STATES_ALL (state, data) {
      state.states = data;
    },
  },
  getters: {
  },
};

import axios from 'axios';
import Vue from 'vue';
import store from '@/store';
import broadcastChannel from '@/plugins/broadcastChannel';
import Cookies from 'js-cookie';

import User from "@/api/user";
import Files from "@/api/files";
import Orders from "@/api/orders";
import Invoices from "@/api/invoices";
import Courses from "@/api/courses";
import CoursePackages from "@/api/coursePackages";
import Pages from "@/api/pages";
import Products from "@/api/products";
import Settings from "@/api/settings";
import Users from "@/api/users";
import Bookings from "@/api/bookings";
import Events from "@/api/events";
import Transactions from "@/api/transactions";
import Jobs from "@/api/jobs";
import Dashboard from "@/api/dashboard";
import PaymentMethods from "@/api/paymentMethods";
import Cities from "@/api/cities";
import Notifications from "@/api/notifications";
import Specialities from "@/api/specialities";
import Coupons from "@/api/coupons";
import Exports from "@/api/exports";
import EventPools from "@/api/eventPools";
import BioPages from "@/api/bioPages";
import Trainings from "@/api/trainings";

let $axios = {};

const ajaxUrl = process.env.VUE_APP_URL_API;

$axios.ajax = axios.create({
  baseURL: ajaxUrl,
  timeout: 60000
});

$axios.ajax.interceptors.request.use(config => {
  const token = Cookies.get('RTT');
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
}, error => {
  return Promise.reject(error);
});

let isAlreadyFetchingAccessToken = false;
let isAlreadyRefreshTokenTabs = false;
let isDuplicateLider = false;
let duplicateLiders = [];
let subscribers = [];

$axios.setIsAlreadyFetchingAccessToken = function (data) {
  isAlreadyFetchingAccessToken = data
}

$axios.setSubscribers = function (data) {
  subscribers = data
}

$axios.onAccessTokenFetched = function (access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

$axios.addSubscriber = function (callback) {
  subscribers.push(callback)
}

$axios.checkToken = async function () {
  try {
    const rt = localStorage.getItem('RRTT');
    const token = Cookies.get('RTT');

    if (token && rt) {
      let res = await store.dispatch('refreshToken', {refresh_token: rt});

      await broadcastChannel.channel.postMessage({type: 'login', payload: res.data});
    } else {
      return Promise.reject('Unauthenticated');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

$axios.refreshTokenTabs = async function () {
  if (broadcastChannel.elector && !broadcastChannel.elector.hasLeader) {
    await broadcastChannel.elector.awaitLeadership()
    if (broadcastChannel.elector.isLeader) {
      store.commit('SET_LEADER_TAB', true);
    }
  }
  let leader;
  if (isDuplicateLider) {
    leader = duplicateLiders.sort()[0] === broadcastChannel.elector.token;
  } else {
    leader = broadcastChannel.elector.isLeader;
  }
  if (leader && !isAlreadyRefreshTokenTabs) {
    isAlreadyRefreshTokenTabs = true;
    $axios.checkToken()
      .then(() => {
        isAlreadyFetchingAccessToken = false;
        broadcastChannel.channel.postMessage({type: 'setIsAlreadyFetchingAccessToken', payload: false});
        const token = Cookies.get('RTT');
        $axios.onAccessTokenFetched(token);
        broadcastChannel.channel.postMessage({type: 'onAccessTokenFetched', payload: token});
        setTimeout(() => isAlreadyRefreshTokenTabs = false, 5000);
      })
      .catch(error => {
        if (!isDuplicateLider || (isDuplicateLider && duplicateLiders.sort()[0] === broadcastChannel.elector.token)) {
          store.dispatch('logout');
          broadcastChannel.channel.postMessage({type: 'logout'});
          store.commit('SET_SNACK', {text: error, type: 'error'});
          isAlreadyFetchingAccessToken = false;
          subscribers = [];
          isAlreadyRefreshTokenTabs = false;
          broadcastChannel.channel.postMessage({type: 'setIsAlreadyFetchingAccessToken', payload: false});
          broadcastChannel.channel.postMessage({type: 'setSubscribers', payload: []});
        } else {
          isAlreadyFetchingAccessToken = false;
          broadcastChannel.channel.postMessage({type: 'setIsAlreadyFetchingAccessToken', payload: false});
          const token = Cookies.get('RTT');
          $axios.onAccessTokenFetched(token);
          broadcastChannel.channel.postMessage({type: 'onAccessTokenFetched', payload: token});
          setTimeout(() => isAlreadyRefreshTokenTabs = false, 5000);
        }
      })
      .finally(() => {
        setTimeout(() => {
          isAlreadyFetchingAccessToken = false;
          broadcastChannel.channel.postMessage({type: 'setIsAlreadyFetchingAccessToken', payload: false});
        }, 10000)
        setTimeout(() => {
          isAlreadyFetchingAccessToken = false;
          broadcastChannel.channel.postMessage({type: 'setIsAlreadyFetchingAccessToken', payload: false});
        }, 20000)
      })
  }
}

$axios.ajax.interceptors.response.use(response => {
  return response;
}, error => {
  if (!error.response) {
    if (!error.code || error.code !== 'ERR_CANCELED') {
      return Promise.reject({data: {message: 'Internal Server Error'}});
    } else if (error.code && error.code === 'ERR_CANCELED') {
      return Promise.reject({canceled: true});
    }
  }

  const { config, response: { status } } = error;
  const originalRequest = config;

  if (originalRequest.url.includes('jwt/refresh') && status === 401) {
    if (!isDuplicateLider || (isDuplicateLider && duplicateLiders.sort()[0] === broadcastChannel.elector.token)) {
      store.dispatch('logout');

      broadcastChannel.channel.postMessage({type: 'logout'});
      store.commit('SET_SNACK', {text: 'Unauthenticated', type: 'error'});
      isAlreadyFetchingAccessToken = false;
      subscribers = [];
      broadcastChannel.channel.postMessage({type: 'setIsAlreadyFetchingAccessToken', payload: false});
      broadcastChannel.channel.postMessage({type: 'setSubscribers', payload: []});
    }
    return Promise.reject({data: {message: 'Token expired'}});
  }

  if (status === 401) {
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      broadcastChannel.channel.postMessage({type: 'setIsAlreadyFetchingAccessToken', payload: true});
      broadcastChannel.channel.postMessage({type: 'refreshTokenTabs'});
      $axios.refreshTokenTabs()
    }
    return new Promise((resolve) => {
      $axios.addSubscriber(access_token => {
        originalRequest.headers.Authorization = 'Bearer ' + access_token;
        resolve(axios(originalRequest))
      })
    })
  }

  if (error.response.status === 402 && !error.config._retry) {
    error.config._retry = true;

    return Promise.reject(error.response ? error.response : error);
  }
  if (error.response.status === 403 && !error.config._retry) {
    error.config._retry = true;

    return Promise.reject(error.response ? error.response : error);
  }
  if (error.response.status === 404 && !error.config._retry) {
    error.config._retry = true;

    return Promise.reject(error.response ? error.response : error);
  }
  if (error.response.status === 500 && !error.config._retry) {
    error.config._retry = true;

    return Promise.reject(error.response ? error.response : error);
  }

  return Promise.reject(error.response ? error.response : error);
});

const factories = {
  user: User($axios.ajax),
  files: Files($axios.ajax),
  orders: Orders($axios.ajax),
  invoices: Invoices($axios.ajax),
  courses: Courses($axios.ajax),
  coursePackages: CoursePackages($axios.ajax),
  pages: Pages($axios.ajax),
  products: Products($axios.ajax),
  settings: Settings($axios.ajax),
  users: Users($axios.ajax),
  bookings: Bookings($axios.ajax),
  events: Events($axios.ajax),
  transactions: Transactions($axios.ajax),
  jobs: Jobs($axios.ajax),
  dashboard: Dashboard($axios.ajax),
  paymentMethods: PaymentMethods($axios.ajax),
  cities: Cities($axios.ajax),
  notifications: Notifications($axios.ajax),
  specialities: Specialities($axios.ajax),
  coupons: Coupons($axios.ajax),
  exports: Exports($axios.ajax),
  eventPools: EventPools($axios.ajax),
  bioPages: BioPages($axios.ajax),
  trainings: Trainings($axios.ajax),
};

Vue.prototype.$axios = $axios.ajax;
Vue.prototype.$api = factories;

$axios.checkUpdate = function () {
};

export default $axios;

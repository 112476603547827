import Cookies from "js-cookie";
import {appRouter, bottomRouter} from '@/router/routers';
import Vue from 'vue';

const sizeUtil = function (size) {
  return size >= 1264;
};

const show_menu_expand = Cookies.get('showMenuExpand') ? Cookies.get('showMenuExpand') === 'true' : false;

export default {
  state: {
    showMenuExpand: show_menu_expand,
    showNotificationPanel: false,
    preloaderApp: true,
    windowWidth: 0,
    windowActive: true,
    showMenu: false,
    rowsPerPageItems: [5,10,25,50],
    leaderTab: false,
    menuList1: [],
    menuList2: [],
    bottomMenuList: [],
    srcForDialogImg: '',
    downloadedFiles: [],
    minimizeDownloadedFiles: [],
    snack: '',
    snackType: '',
    snackDelay: 7000,
  },
  actions: {
    updateMenulist ({commit, rootState}) {
      const role = rootState.user.user.role;

      let menu = {
        menuList1: [],
        menuList2: [],
      }

      appRouter.forEach((item) => {
        if (item.children?.length > 1) {
          if (item.access.includes(role)) {
            let children = [];
            item.children.forEach(child => {
              if (child.access.includes(role)) children.push(child)
            })
            menu[`${item.menuList}`].push(Object.assign({}, item, {children: children}))
          }
        } else if (item.access) {
          if (item.access.includes(role)) menu[`${item.menuList}`].push(item)
        } else {
          menu[`${item.menuList}`].push(item)
        }
      });
      commit('SET_MENU_LIST1',  menu.menuList1);
      commit('SET_MENU_LIST2',  menu.menuList2);
    },
    updateBottomMenulist ({commit, rootState}) {
      const role = rootState.user.user.role;

      let menuList = [];
      bottomRouter.forEach((item) => {
        if (item.children?.length > 1) {
          if (item.access.includes(role)) {
            let children = [];
            item.children.forEach(child => {
              if (child.access.includes(role)) children.push(child)
            })
            menuList.push(Object.assign({}, item, {children: children}))
          }
        } else if (item.access) {
          if (item.access.includes(role)) menuList.push(item)
        } else {
          menuList.push(item)
        }
      });
      commit('SET_BOTTOM_MENU_LIST', menuList);
    },
  },
  mutations: {
    SET_MENU_LIST1 (state, menuList) {
      state.menuList1 = menuList;
    },
    SET_MENU_LIST2 (state, menuList) {
      state.menuList2 = menuList;
    },
    SET_BOTTOM_MENU_LIST (state, menuList) {
      state.bottomMenuList = menuList;
    },
    SET_LEADER_TAB (state, value) {
      state.leaderTab = value;
    },
    SET_PRELOADER_APP (state, value) {
      state.preloaderApp = value;
      window.dispatchEvent(new Event('resize'));
    },
    SET_SHOW_MENU (state) {
      state.showMenu = !state.showMenu;
    },
    SET_SHOW_MENU_EXPAND (state) {
      const newValue = !state.showMenuExpand;
      state.showMenuExpand = newValue;
      Cookies.set('showMenuExpand', newValue, {expires: 365});
    },
    SET_SHOW_NOTIFICATIONS_PANEL (state) {
      state.showNotificationPanel = !state.showNotificationPanel;
    },
    SET_WINDOW_WIDTH (state, windowWidth) {
      if (state.windowWidth !== windowWidth) {
        state.windowWidth = windowWidth;
        state.showMenu = sizeUtil(state.windowWidth);
        state.showMenuChat = sizeUtil(state.windowWidth);
      }
    },
    SET_WINDOW_ACTIVE (state, windowActive) {
      state.windowActive = windowActive;
    },
    SET_DOWNLOAD_FILE (state, file) {
      const i = state.downloadedFiles.findIndex(f => f.id === file.id);
      i > -1 ? Vue.set(state.downloadedFiles, i, file) : state.downloadedFiles.push(file);
    },
    DEL_DOWNLOAD_FILE (state, id) {
      const i = state.downloadedFiles.findIndex(f => f.id === id);
      if (i > -1) state.downloadedFiles.splice(i, 1);
    },
    MINIMIZE_DOWNLOAD_FILE (state, id) {
      if (!state.minimizeDownloadedFiles.includes(id)) state.minimizeDownloadedFiles.push(id);
    },
    DEL_MINIMIZE_DOWNLOAD_FILE (state, id) {
      const i = state.minimizeDownloadedFiles.findIndex(f => f === id);
      if (i > -1) state.minimizeDownloadedFiles.splice(i, 1);
    },
    DEL_ALL_MINIMIZE_DOWNLOAD_FILE (state) {
      Vue.set(state, 'minimizeDownloadedFiles', [])
    },
    SET_SRC_FOR_DIALOG_IMG(state, src) {
      state.srcForDialogImg = src;
    },

    SET_SNACK(state, {text, type, delay = 7000}) {
      if (typeof text === 'string') {
        state.snack = text;
        // state.snack = text === '' ? 'Error' : text;
      } else {
        let string = '';
        for (let [key, value] of Object.entries(text)) {
          if (typeof value === 'string') {
            string = `${string} ${key === 'message' ? value + ' ' : ''}`
          } else if (value.length && Array.isArray(value) && typeof value !== 'string') {
            if (value.length > 10) {
              string = string + ' '
            } else {
              value.forEach((val) => {
                if (typeof val === 'string') {
                  string = string + val + ' '
                } else {
                  for (let [k, v] of Object.entries(val)) {
                    if (typeof v === 'string' && k === 'message') {
                      string = string + v + ' '
                    }
                  }
                }
              })
            }
          } else {
            for (let [name, str] of Object.entries(value)) {
              if (str.length && Array.isArray(str) && typeof str !== 'string') {
                str.forEach((s) => {
                  if (typeof s === 'string') {
                    string = string + s + ' '
                  } else {
                    for (let [k, v] of Object.entries(s)) {
                      if (typeof v === 'string' && k === 'message') {
                        string = string + v + ' '
                      }

                    }
                  }
                })
              } else {
                if (name === 'message') string = string + str + ' '
              }
            }
          }
        }

        state.snack = string.trim() === '' ? 'Error' : string;
      }
      state.snackType = type;
      state.snackDelay = delay;
    },
  },
  getters: {}
};

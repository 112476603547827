export default {
  state: {
  },
  actions: {
    async getOrders (state, params) {
      return await this._vm.$api.orders.getOrders(params)
    },
    async getOrder (state, params) {
      return await this._vm.$api.orders.getOrder(params)
    },
    async postOrder (state, params) {
      return await this._vm.$api.orders.postOrder(params)
    },
    async deleteOrder (state, params) {
      return await this._vm.$api.orders.deleteOrder(params)
    },
    async completePaymentOrder (state, params) {
      return await this._vm.$api.orders.completePaymentOrder(params)
    },
  },
  mutations: {
  },
  getters: {
  },
};

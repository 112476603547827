export default axios => ({
  getCourses(params) {
    return axios.get('/admin/courses', {params})
  },
  getCourse(params) {
    return axios.get(`/admin/courses/${params.id}`, {params})
  },
  postCourse(params) {
    return axios.post('/admin/courses', params)
  },
  putCourse(params) {
    return axios.post(`/admin/courses/${params.get('id')}`, params)
  },
  deleteCourse(params) {
    return axios.delete(`/admin/courses/${params.id}`)
  },
  shareCourses(params) {
    return axios.post('/admin/courses/share', params)
  },
});

export default {
  state: {
    couponsAll: [],
  },
  actions: {
    async getCoupons (state, params) {
      return await this._vm.$api.coupons.getCoupons(params)
    },
    async getCoupon (state, params) {
      return await this._vm.$api.coupons.getCoupon(params)
    },
    async postCoupon (state, params) {
      return await this._vm.$api.coupons.postCoupon(params)
    },
    async putCoupon (state, params) {
      return await this._vm.$api.coupons.putCoupon(params)
    },
    async deleteCoupon (state, params) {
      return await this._vm.$api.coupons.deleteCoupon(params)
    },
    async getCouponsAll ({ commit }, params) {
      try {
        const param = Object.assign({}, params, {
          count: 'nolimit'
        });

        const res = await this._vm.$api.coupons.getCoupons(param);
        let data = res.data.data ? res.data.data : res.data;
        commit('SET_COUPONS_ALL', data);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
  },
  mutations: {
    SET_COUPONS_ALL (state, data) {
      state.couponsAll = data;
    },
  },
  getters: {
  },
};

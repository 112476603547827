export default {
  state: {
  },
  actions: {
    async getDashboard (state, params) {
      return await this._vm.$api.dashboard.getDashboard(params)
    },
  },
  mutations: {
  },
  getters: {
  },
};

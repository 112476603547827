export default axios => ({
  getEvents(params) {
    return axios.get('/admin/events', {params})
  },
  getEvent(params) {
    return axios.get(`/admin/events/${params.id}`, {params})
  },
  postEvent(params) {
    return axios.post('/admin/events', params)
  },
  putEvent(params) {
    return axios.put(`/admin/events/${params.id}`, params)
  },
  deleteEvent(params) {
    return axios.delete(`/admin/events/${params.id}`)
  },
  notifyEvent(params) {
    return axios.post('/admin/events/notify', params)
  },
});

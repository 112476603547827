export default axios => ({
  getJobs(params) {
    return axios.get('/admin/jobs', {params})
  },
  getJob(params) {
    return axios.get(`/admin/jobs/${params.id}`, {params})
  },
  postJob(params) {
    return axios.post('/admin/jobs', params)
  },
  putJob(params) {
    return axios.post(`/admin/jobs/${params.get('id')}`, params)
  },
  deleteJob(params) {
    return axios.delete(`/admin/jobs/${params.id}`)
  },
});

export default axios => ({
  getUsers(params) {
    return axios.get('/admin/users', {params})
  },
  getUser(params) {
    return axios.get(`/admin/users/${params.id}`, {params})
  },
  postUser(params) {
    return axios.post('/admin/users', params, { params: {with: ['paymentMethods']}})
  },
  putUser(params) {
    return axios.put(`/admin/users/${params.id}`, params, { params: {with: ['paymentMethods']}})
  },
  deleteUser(params) {
    return axios.delete(`/admin/users/${params.id}`)
  },
  courseCompletionUser(params) {
    return axios.post(`/admin/users/${params.id}/course-completion/update`, params)
  },
  setPassword(params) {
    return axios.post(`/admin/users/${params.id}/notifications/set-password`)
  },
  sendCheckoutLink(params) {
    return axios.post(`/admin/users/${params.id}/notifications/checkout-link`, params)
  },
});

import Vue from "vue";
import {downloadFile} from "@/plugins/downloadFile";

export default {
  state: {
  },
  actions: {
    async getFiles (state, params) {
      return await this._vm.$api.files.getFiles(params)
    },
    async getFile (state, params) {
      return await this._vm.$api.files.getFile(params)
    },
    async postFile (state, params) {
      return await this._vm.$api.files.postFile(params)
    },
    async putFile (state, params) {
      return await this._vm.$api.files.putFile(params)
    },
    async deleteFile (state, params) {
      return await this._vm.$api.files.deleteFile(params)
    },
    async downloadFile (state, params) {
      try {
        const response = await this._vm.$api.files.downloadFile(params);
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'processed'});
        await downloadFile(response.data, params.data.filename, response.headers['content-type']);
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'completed'});
      } catch (error) {
        if (!error?.canceled) state.commit('SET_SNACK', {text: error.data || '', type: 'error'});
        Vue.prototype.$eventBus.$emit('downloadChangeStatus', {id: params.data.id, status: 'canceled'});
      } finally {
        if (state.rootState.app.minimizeDownloadedFiles.includes(params.data.id)) {
          state.commit('DEL_DOWNLOAD_FILE', params.data.id);
          state.commit('DEL_MINIMIZE_DOWNLOAD_FILE', params.data.id);
        }
      }
    },
  },
  mutations: {
  },
  getters: {
  },
};
